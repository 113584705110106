<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->

    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <div class="section-content">
          <div class="container">
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-12 col-md-12 m-b30 text-justify">
                <p>
                  Sistemele antiincendiu conțin echipamentele de detecție incendiu și avertizare și functionează cu
                  ajutorul semnalelor venite de la detectorii de fum, de temperatură și de la butoanele de
                  avertizare manuală a incendiului.
                </p>
                <p>
                  Sistemele instalate de către noi, implică atât instalarea unei
                  tehnici de ultimă generație de detecție a incendiului cât și
                  proiectarea și instalarea, acestea realizându-se de către
                  inginerii și tehnicienii noștri din departamentul tehnic.
                </p>
                <h3>Sisteme de Detectie Incendiu. </h3>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Solutii tehnice oferite de SKL Technion Solution</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Centrale detecție incendiu adresabile sau
                            convenționale;
                          </li>
                          <li>
                            Detectoare de fum optice, cu ionizare, cu spot reflexiv
                            în infraroșu, tip barieră în infraroșu, prin
                            aspirație;
                          </li>
                          <li>
                            Detectoare de temperatură, de monoxid de carbon, de
                            flacără, de substanțe periculoase;
                          </li>
                          <li>
                            Module adresabile, repertoare și emulatoare,
                            izolatoare de buclă, electromagneți și elemente de
                            blocare acumulatori;
                          </li>
                          <li>
                            Sirene și indicatoare optice de semnalizare, butoane
                            de panică.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  Principala componentă a unui sistem de detecție incendiu este
                  centrala de incendiu. Puteți opta pentru una din cele două
                  tipuri de centrală existente convențională sau adresabilă.
                  Diferența între cele două fiind modalitatea de conectare a
                  detectorilor, numărul acestora, citirea și interpretarea
                  informațiilor.
                </p>
                <p>
                  <b>
                    Cum trebuie sa fie sistemul de detecție incendiu pentru a fi
                    în conformitate cu noul normativ?</b>
                </p>
                <p>
                  <b>Centrala convenţională:</b> Supraveghează semnale de stare
                  venite de la detectorii de fum, temperatură, de la butoanele
                  de avertizare manuală a incendiului sau din raportarea
                  schimbărilor de stare. Detectorii de fum sunt conectați la
                  intrările centralei, definite că zone, și sunt corelați cu
                  zonarea obiectivului. Acest tip de centrală se utilizează
                  atunci când numărul detectorilor de fum este mic.
                </p>
                <p>
                  <b>Centrala adresabilă:</b> Principalul avantajul este
                  ușurința cablării și identificarea ușoară a alarmelor. Prin
                  intermediul modulelor adresabile, la centrală pot fi conectați
                  detectori convenționali sau alte tipuri de detectori incendiu
                  (gaz, monoxid de carbon etc.). Fiecare detector are o adresă
                  și se conectează pe o buclă. Acest tip de centrală se
                  recomandată pentru obiectivele mijlocii și mari.
                </p>
                <p><b>Sisteme de detecție incendiu. Componente:</b></p>
                <p>
                  Un Sistem de detecție incendiu are funcția de avertizare în
                  momentul apariției unui eveniment și de semnalizare optică sau
                  acustică a acestuia prin controlul permananet al detectorilor.
                </p>
                <ol class="order-list">
                  <li>Centrale de incendiu;</li>
                  <li>Detectori;</li>
                  <li>Module adresabile;</li>
                  <li>Repetoare și emulatoare;</li>
                  <li>Izolatoare de buclă pentru sisteme adresabile;</li>
                  <li>Electromagneți și elemente de blocare acumulatori;</li>
                  <li>
                    Dispozitive de avertizare (butoane de urgență pentru
                    declanșarea imediată a alarmei);
                  </li>
                  <li>
                    Dispozitive de semnalizare (sirene și indicatoare optice de
                    semnalizare);
                  </li>
                </ol>
                <p><b>Specificații tehnice</b></p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Centrala</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>Convențională și adresabilă;</li>
                        <li>Zone suportate: 4-8;</li>
                        <li>Max detectori pe zona: 30-250;</li>
                        <li>
                          Unele tipuri de centrală suportă module de extensie
                          pentru mai multe zone.
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Detector</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>Cu cablu sau wireless;</li>
                        <li>
                          De fum (optici, cu ionizare, cu spot relexiv în
                          infra-roșu, tip barieră în infra-roșu, prin
                          aspirație);
                        </li>
                        <li>
                          De temperatură, de flacără, de substanțe periculoase;;
                        </li>
                        <li>
                          Temperatura, rata de creștere și prag de temperatură;
                        </li>
                        <li>CO, gaz și inundații.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <img src="../../../../../assets/images/schema_sistem_antiincendiu-adresabil_sion_solution.jpg"
                      alt="" (click)="
                        openPhoto(
                          'assets/images/schema_sistem_antiincendiu-adresabil_sion_solution.jpg'
                        )
                      " />
                    <p class="img-text">
                      <b>Sistem de detecție incendiu Adresabil</b> – click pe
                      poza pentru a mari
                    </p>
                  </div>
                  <div class="row">
                    <img src="../../../../../assets/images/schema_sistem_antiincendiu-conventional_sion_solution.jpg"
                      alt="" (click)="
                        openPhoto(
                          'assets/images/schema_sistem_antiincendiu-conventional_sion_solution.jpg'
                        )
                      " />
                    <p class="img-text">
                      <b>Sistem de detecție incendiu Conventional</b> – click pe
                      poza pentru a mari
                    </p>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Module adresabile</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Modulele adresabile practic transformă senzorii
                            convenționali în senzori adresabili.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Repetoare și emulatoare</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Repetoarele sunt dispozitive de informare și setare a
                            parametrilor centralelor.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Izolatoare de buclă pentru sisteme adresabile</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            În cazul unei defecțiuni pe buclă, aceste izololează acea porțiune din buclă, astfel încât
                            restul buclei să funcționeze în continuare.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Electromagneți și elemente de blocare, acumulator</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Sunt accesorii folosite în sistemele de detecție
                            incendiu, electromagneții pentru deschiderea ușilor de
                            urgență, iar acumulatorii pentru a asigura back-ul
                            sistemului în cazul căderilor de tensiune.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Buton incendiu</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>Cu sticlă, cu plastic;</li>
                        <li>Semnalizare vizuală.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Sirena</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Convențională – reglaj volum, reglaj tonuri și
                            autoprotejată;
                          </li>
                          <li>De interior și exterior;</li>
                          <li>Putere 100 Db.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  Pentru mai multe detalii și oferta personalizată trimite o
                  CERERE DE OFERTĂ sau contactează specialiștii SKL Technion
                  Solution.
                </p>
                <h3>Sisteme de desfumare</h3>
                <p>
                  Evacuarea fumului și a gazelor fierbinți (desfumarea) este
                  procesul de extragere din spațiile incendiate a unei părți din
                  fumul și gazele de ardere.
                </p>
                <p>
                  Scopul este asigurarea condițiilor de evacuare a
                  utilizatorilor și a folosirii mijloacelor de intervenție la
                  stingere, precum și pentru evacuarea gazelor fierbinți
                  degajate de incendiu în fazele de dezvoltare.
                </p>
                <p>
                  Proiectarea și implementarea acestor instalații, ca mijloace
                  de protecție la incendiu asigură în primul rând menținerea
                  liberă de fum a căilor de evacuare și acces. Un alt avantaj îl reprezintă eficiența asupra stingerii
                  incendiului în faza incipientă. În plus, instalațiile de evacuare a fumului și a gazelor fierbinți
                  contribuie la reducerea efectului de ardere generalizată, la protecția echipamentelor și a
                  mobilierului și, în final, reduce pagubele cauzate de descompunerea termică.
                </p>
                <p>
                  <b>SKL Technion Solution</b> oferă servicii de instalare și
                  întreținere a sistemelor și instalațiilor de ventilare pentru
                  evacuarea fumului și a gazelor fierbinți.
                </p>
                <p>
                  <b>Desfumarea se poate realiza în mod natural sau mecanic:</b>
                </p>
                <p>
                  <b>1) Desfumarea naturală</b> (prin tiraj natural organizat)
                  se realizează prin evacuarea fumului și introducerea de aer
                  proaspăt, direct sau prin tubulatură având legătură direct cu
                  exteriorul, realizată astfel ca să asigure o eliminare
                  satisfăcătoare a fumului din spațiul respectiv;
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Evacuarea naturală a fumului se poate face prin unul
                          din următoarele sisteme:</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>prin ferestre de desfumare în fațadă;</li>
                        <li>prin trape pe acoperiș;</li>
                        <li>prin guri de evacuarea fumului;</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Introducerea de aer proaspăt (denumit și aer de
                          compensare) se poate face prin unul din următoarele
                          sisteme:</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>prin ferestrele de desfumare din fațadă;</li>
                        <li>
                          prin ușile spațiului care se desfumează, care dau spre
                          exterior sau spre volume ce pot fi ușor aerisite;
                        </li>
                        <li>prin scări deschise;</li>
                        <li>prin guri de introducere;</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  <b>2) Desfumarea mecanică</b> (prin tiraj mecanic) este
                  asigurată prin extragerea mecanică a fumului și introducerea
                  de aer proaspăt în mod natural sau tot pe cale mecanică, cu
                  dispozitive astfel dispuse încât să asigure o desfumare a
                  volumului considerat. Desfumarea mecanică și dirijarea fumului
                  se realizeză cu:
                </p>
                <ul class="list-check-circle primary">
                  <li>clapeți rezistenți la foc;</li>
                  <li>voleți de desfumare;</li>
                  <li>grile rezistente la foc;</li>
                </ul>
                <p><b>Sisteme de desfumare – Tipuri trape de fum:</b></p>
                <ul class="list-check-circle primary">
                  <li>
                    <b>Trapă de fum electrică</b> – are ca mecanism de
                    deschidere un actuator electric alimentat la 24V;
                  </li>
                  <li>
                    <b>Trapă de fum pneumatică</b> – are mecanism de deschidere
                    un piston pneumatic ce este acționat cu gaz (CO2).
                    Deschiderea se poate realiza cu impuls pneumatic, prin
                    traseu țeava de cupru și butelie CO sau/și cu impuls
                    electric (24V);
                  </li>
                  <li>
                    <b>Trapă de fum pirotcehnică</b> – are mecanismul de
                    deschidere un ax metalic introdus într-o teacă la baza
                    căreia este montată o încărcătură explozibilă. Deschiderea
                    se poate realiza cu impuls pneumatic, prin traseu teava de
                    cupru si butelie CO sau/si cu impuls electric (24V);
                  </li>
                  <li>
                    <b>Trapă de fum mecanică</b> – are mecanismul de deschidere
                    format dintr-un tandem de 2 sau 3 resorturi (telescoape)
                    oleo-pneumatice – comprimare cu gaz. Deschiderea se poate
                    realiza cu impuls pneumatic, prin traseu țeavă de cupru și
                    butelie CO sau/și cu impuls electric (24V). sisteme de
                    desfumare;
                  </li>
                </ul>
                <p><b>Centrale de desfumare</b></p>
                <p>
                  Pentru comanda și declanșarea sistemelor de acționare pentru
                  evacuarea fumului și a gazelor fierbinți, precum și regimul de
                  ventilare zilnică în proiecte de construcții mai mici sau în
                  casa scărilor se utilizează centrale de desfumare.
                </p>
                <p>
                  <b>SKL Technion Solution</b> are o experiență considerabilă în
                  proiectarea și implementarea acestor sisteme de desfumare si
                  de control al fumului. Vă oferim servicii de instalare și
                  întreținere a sistemelor și instalațiilor de ventilare pentru
                  evacuarea fumului și a gazelor fierbinți, sisteme
                  antiincendiu.
                </p>
                <p>
                  Toate sistemele noastre sunt concepute special pentru a se
                  îmbina perfect în structura clădirii. Sunt conforme cu toate
                  standardele impuse de legislația în vigoare
                  <a target="_blank"
                    href="https://www.mdrap.ro/userfiles/reglementari/Domeniul_XXVIII/28_15_P118_2_2013.pdf">conform
                    normativ P118/2-2013.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>