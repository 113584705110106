<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- FAQ SECTION START -->
        <div class="section-full p-t20 p-b40 bg-white">
            <div class="container">

                <div class="section-content">

                    <div class="row d-flex justify-content-center">
<!-- stai aci -->
                        <div class="m-b20 p-a20 d-flex flex-column justify-content-center">
                            <h2 class="m-t0 m-b0">{{selectedJob.name}}</h2>
                            <!-- <button class="site-button site-btn-effect">Aplică acum</button> -->
                        </div>

                        <div class=" col-lg-8 col-md-6 col-sm-12">

                            <!-- Accordian -->
                            <div class="wt-accordion acc-bg-gray faq-accorfion text-justify" id="accordion5">


                                <!-- CRITERII DE SELECTIE -->
                                <div class="panel wt-panel">
                                    <div class="card-sam">
                                        <div class="card-header-sam">
                                          <h3 class="card-title-sam">Criterii de selecție</h3>
                                          <button class="collapse-button-sam">-</button>
                                        </div>
                                        <div class="card-body-sam">
                                          <ul>
                                            <li *ngIf="!isCriteriiSelectieArray">{{ selectedJob.criterii_selectie }}</li>
                                            <ng-container *ngIf="isCriteriiSelectieArray">
                                              <li *ngFor="let item of selectedJob.criterii_selectie">{{ item }}</li>
                                            </ng-container>
                                          </ul>
                                        </div>
                                      </div>
                                </div>

                                <!-- CANDIDATUL IDEAL -->
                                <div class="panel wt-panel">
                                    <div class="card-sam">
                                        <div class="card-header-sam">
                                          <h3 class="card-title-sam">Candidatul ideal</h3>
                                          <button class="collapse-button-sam">-</button>
                                        </div>
                                        <div class="card-body-sam">
                                          <ul>
                                            <li *ngFor="let row of selectedJob.canditatul_ideal">
                                                {{row}}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                </div>

                                <!-- DESCRIEREA JOBULUI -->
                                <div class="panel wt-panel">
                                    <div class="card-sam">
                                        <div class="card-header-sam">
                                          <h3 class="card-title-sam">Descrierea jobului</h3>
                                          <button class="collapse-button-sam">-</button>
                                        </div>
                                        <div class="card-body-sam">
                                          <ul>
                                            <li *ngFor="let row of selectedJob.descrierea_jobului">
                                                {{row}}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                </div>

                                <!-- SE CERE -->
                                <div class="panel wt-panel">
                                    <div class="card-sam">
                                        <div class="card-header-sam">
                                          <h3 class="card-title-sam">Se cere</h3>
                                          <button class="collapse-button-sam">-</button>
                                        </div>
                                        <div class="card-body-sam">
                                          <ul>
                                            <li *ngFor="let row of selectedJob.se_cere">
                                                {{row}}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                </div>

                                <!-- SE OFERA -->
                                <div class="panel wt-panel">
                                    <div class="card-sam">
                                        <div class="card-header-sam">
                                          <h3 class="card-title-sam">Se oferă</h3>
                                          <button class="collapse-button-sam">-</button>
                                        </div>
                                        <div class="card-body-sam">
                                          <ul>
                                            <li *ngFor="let row of selectedJob.se_ofera">
                                                {{row}}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12">

                            <div class="faq-sidebar">
                                <div class="wt-box p-a30 need-help site-bg-primary text-white m-b30">
                                    <h3 class="m-t0">Ai întrebări?</h3>
                                    <p>Contactează-ne: </p>
                                    <ul class="m-l30">
                                        <li>Email: <strong class="prima"> officesklsolution@gmail.ro</strong></li>
                                        <li>Email: <strong class="prima"> office@skl.ro</strong></li>
                                        <li>Tel: <strong> 0723 000 585</strong></li>
                                        <li>Tel: <strong> 0740 010 252</strong></li>
                                        <li>Tel: <strong> 0745 777 585</strong></li>
                                    </ul>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>
        </div>
        <!-- FAQ SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
 