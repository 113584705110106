import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ServicesService {

    all: ServiceDescription[] = [
        new ServiceDescription({
            title: "SISTEME FOTOVOLTAICE",
            lowercaseTitle: "Sisteme fotovoltaice",
            list: [
                "Industriale",
                "Rezidențiale",
                "Parcuri",
                "Stații de încărcare",
                "Soluții de stocare a energiei - Baterii",
                "Sisteme de alimentare de siguranță și rezervă ( backup-uri ) - Generatoare, UPS-uri" ,     
            ],
            icon: "assets/icons/solar.png",
            path: "/servicii/sisteme-fotovoltaice"
        }),

        new ServiceDescription({
            title: "INSTALAȚII DE JOASĂ TENSIUNE",
            lowercaseTitle: "Instalații electrice joasă tensiune",
            list: [
                "Instalații electrice interioare și exterioare, curenți tari și slabi, pentru obiective civile și industriale",
                "Iluminat de siguranță și antipanică",
                "Legare la pământ și paratrăznet",
                "Tablouri electrice de distribuție",
                "Branșamente de joasă tensiune",
                "Iluminat public și edilitar",
            ],
            icon: "assets/icons/electric.png",
            path: "/servicii/instalatii-electrice-joasa-tensiune",
        }),

        new ServiceDescription({
            title: "INSTALAȚII DE MEDIE TENSIUNE",
            lowercaseTitle: "Instalații electrice medie tensiune",
            list: [
                "Linii electrice aeriene de medie tensiune - LEA MT",
                "Linii electrice subterane de medie tensiune - LES MT",
                "Posturi de transformare - PT",
                "Branșamente de medie tensiune",
            ],
            icon: "assets/icons/medium-power-voltage.png",
            path: "/servicii/instalatii-electrice-medie-tensiune",
        }),

        new ServiceDescription({
            title: "SISTEME DE SECURITATE",
            lowercaseTitle: "Sisteme de securitate",
            list: [
                "Sisteme de supraveghere video - cctv",
                "Sisteme antiefracție",
                "Sisteme de interfonie & videointerfonie",
                "Sisteme de control acces - ca",
                "Sisteme de sonorizare",
                "Cablare structurată",
            ],
            icon: "assets/icons/video.png",
            path: "/servicii/sisteme-de-securitate",
        }),
        new ServiceDescription({
            title: "MONITORIZARE ȘI MENTENANȚĂ",
            lowercaseTitle: "Monitorizare și mentenanță",
            list: [
                "Sisteme fotovoltaice - cef",
                "Sisteme antiincendiu",
                "Sisteme și echipamente de securitate",
                "Mentenanță generală preventivă, predictivă și corectivă pentru sisteme și instalații care asigură funcționalitatea clădirilor: electrice, mecanice, telecomunicații, curenți slabi, curenți tari",
                "Verificări și revizuiri periodice ale stării echipamentelor",
            ],
            icon: "assets/icons/maintenance.png",
            path: "/servicii/monitorizare-si-mentenanta",
        }),
        new ServiceDescription({
            title: "PROIECTARE ȘI CONSULTANȚĂ",
            lowercaseTitle: "Proiectare și consultanță",
            list: [
                "Proiectare instalații electrice interioare și exterioare, curenți tari și slabi, pentru obiective civile și industriale",
                "Consultanță tehnică și inginerie",
                "Studii de fezabilitate",
                "Proiecte tehnice",
            ],
            icon: "assets/icons/design.png",
            path: "/servicii/proiectare-si-consultanta",
        }),
        new ServiceDescription({
            title: "SISTEME ANTIINCENDIU",
            lowercaseTitle: "Sisteme antiincendiu",
            list: [
                "Detecție semnalizare și avertizare incendiu - idsai",
                "Desfumare - desf",
                "Monoxid de carbon - co",
            ],
            icon: "assets/icons/fire.png",
            path: "/servicii/sisteme-antiincendiu",
        }),
        new ServiceDescription({
            title: "AUTOMATIZĂRI PENTRU CLĂDIRI ȘI EFICIENȚĂ ENERGETICĂ",
            lowercaseTitle: "Automatizări pentru clădiri și eficiență energetică",
            list: [
                "Soluții pentru gestiunea și controlul temperaturii și al iluminatului",
                "Monitorizare a consumului de energie",
                "Audit energetic",
                "Eficiență energetică (ZECO)",
                "Soluții integrate de management al clădirilor ( BMS ) și tehnologii SMART",
                "Programare și configurare a controlerelor",
                "Soluții de afișare și configurare a controlerelor",
                "Automatizări electrice",
            ],
            icon: "assets/icons/energy.png",
            path: "/servicii/eficienta-energetica",
        }),
        new ServiceDescription({
            title: "MĂSURĂTORI DIVERSE ȘI DEFECTOSCOPIE",
            lowercaseTitle: "Măsurători diverse și defectoscopie",
            list: [
                "Măsurători energetice - PRAM",
                "Verificări și măsurători tehnice la instalații care necesită aviz ISCIR",
                "Defectoscopie IR",
                "Termoviziune",
            ],
            icon: "assets/icons/energy.png",
            path: "/servicii/card-1",
        }),
        new ServiceDescription({
            title: "INSTALAȚII SANITARE",
            lowercaseTitle: "Instalații sanitare",
            list: [
                
            ],
            icon: "assets/icons/energy.png",
            path: "/servicii/card-2",
        }),
        new ServiceDescription({
            title: "INSTALATII HVAC",
            lowercaseTitle: "Instalații HVAC",
            list: [
             
            ],
            icon: "assets/icons/energy.png",
            path: "/servicii/card-3",
        }),
        new ServiceDescription({
            title: "INSTALAȚII GAZ",
            lowercaseTitle: "Instalații gaz",
            list: [
                
            ],
            icon: "assets/icons/energy.png",
            path: "/servicii/card-4",
        }),
    ]
}

export class ServiceDescription {
    title: string;
    lowercaseTitle: string;
    description: string;
    icon: string;
    list: string[];
    isActive: boolean = true;
    path: string = "";

    public constructor(init?: Partial<ServiceDescription>) {
        Object.assign(this, init);
    }
}
