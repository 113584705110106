import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-2',
  templateUrl: './card-2.component.html',
  styleUrls: ['./card-2.component.css']
})
export class Card2 implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "INSTALAȚII SANITARE",
  }

}
