<div class="page-wraper">
    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->
  
    <!-- CONTENT START -->
    <div class="page-content">
      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->
  
      <!-- FAQ SECTION START -->
      <div class="section-full p-t20 p-b40 bg-white">
        <div class="container">
          <div class="section-content">
            <div class="container">
              <div class="row d-flex justify-content-center flex-wrap ">
                <div class="col-lg-12 col-md-12 m-b30 text-justify">
                  <h3>În curs de lucru</h3>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FAQ SECTION END -->
    </div>
    <!-- CONTENT END -->
  
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
  </div>
  
  <app-loader></app-loader>