import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-3',
  templateUrl: './card-3.component.html',
  styleUrls: ['./card-3.component.css']
})
export class Card3 implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "INSTALAȚII HVAC",
  }

}
