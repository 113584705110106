import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-1',
  templateUrl: './card-1.component.html',
  styleUrls: ['./card-1.component.css']
})
export class Card1 implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "MĂSURĂTORI DIVERSE ȘI DEFECTOSCOPIE",
  }

}
