<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <div class="section-content">
          <div class="container">
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-12 col-md-12 m-b30 text-justify">
                <h3>Sisteme supraveghere video</h3>
                <p>
                  SKL Technion Solution instalează sisteme supraveghere video,
                  menite să monitorizeze în timp real, evenimente și persoane,
                  cât și ulterior, prin stocarea înregistrărilor, imaginilor
                  video pentru o anumită perioadă de timp. Aceste sisteme sunt
                  proiectate de specialiștii noștri în funcție de necesitățile
                  obiectivului asigurat și cerințele clienților, cu scopul
                  obținerii celor mai bune soluții tehnice de securitate.
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Sisteme de supraveghere video oferite de SKL Technion
                          Solution</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <li>Analog și IP;</li>
                        <li>Videorecordere DVR/NVR (digitale și în rețea);</li>
                        <li>Plăci de captură, multiplexoare, monitoare;</li>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Destinații posibile:</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>spații comerciale;</li>
                        <li>depozite, hale de producție;</li>
                        <li>instituții financiar-bancare;</li>
                        <li>spații de birouri;</li>
                        <li>hoteluri, instituții de interes public;</li>
                        <li>
                          cazinouri și alte spații de desfășurare jocuri de
                          noroc;
                        </li>
                        <li>
                          spații rezidențiale (apartamente, vile, cabane, case
                          de vacanță, etc).
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  Parcurile fotovoltaice de la Obedeni, Bucșani, Arcani, Târgu
                  Cărbunești, Simnic și Caracal beneficiază de serviciile
                  implementate de Sion Solution. Sion Solution a instalat și pus
                  în funcțiune sisteme supraveghere video a căilor de acces,
                  clădirilor din interiorul parcurilor precum și panourilor
                  fotovoltaice cu camere IP de exterior.
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Soluția modernă implementată de SKL Technion Solution
                          beneficiază de toate avantajele sistemelor digitale</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>Comenzi simple de exploatare ale sistemului;</li>
                        <li>Interfață grafică specifică arborescentă;</li>
                        <li>Posibilitatea configurării modului de afișare.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Permite</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Acces remote (prin intermediul Internetului și al
                            interfeței grafice);
                          </li>
                          <li>Acces la baza de imagini (înregistrări);</li>
                          <li>Mod de lucru programabil.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <h3>Sisteme de antiefracție</h3>
                <p>
                  Instalăm sistemele de securitate antiefractie care îndeplinesc
                  rolul de protejare a persoanelor, bunurilor și valorilor
                  aflate într-un spațiu bine delimitat, astfel încât nevoia de
                  securitate să fie satisfăcută în proporție de 100%.
                </p>
                <p>
                  Folosirea acestor sisteme pe lângă scopul de protecție are și
                  un puternic efect de descurajare al infractorilor.
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Care este scopul unor sisteme de antiefracție:</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Scopul lor este detectarea, în timp real, a
                            pătrunderii unei persoane neautorizate într-un anumit
                            spațiu protejat și avertizarea sonoră sau vizuală la
                            nivel local. Dacă însă se dorește un grad mai mare de
                            securitate, informația poate fi transmisă și către un
                            centru de monitorizare, care se numește dispecerat de
                            monitorizare.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Soluții tehnice oferite pentru sistemele de antiefracție:</h3> 
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>Centrale alarmă, cu cablu sau fără fir;</li>
                        <li>
                          Detectoare mișcare, vibrații, geam spart, incendiu;
                        </li>
                        <li>
                          Sirene avertizare, tastaturi, contacte magnetice;
                        </li>
                        <li>Comunicatoare digitale/vocale, IP, GSM, PSNT.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">De ce să instalați sisteme antiefracție:</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Sunteți alertați în orice moment, în cazul unei
                            pătrunderi prin efracție;
                          </li>
                          <li>
                            Aveți posibilitatea de a vă conecta la un dispecerat
                            de intervenție;
                          </li>
                          <li>
                            Descurajarea posibililor hoți de a încerca să patrundă
                            în spațiul protejat cu un sistem de detectie efractie,
                            prin poziționarea ușor vizibilă a sirenei exterioare
                            și a stickerelor de marcaj;
                          </li>
                          <li>
                            Posibilitatea de a accesa sistemul de pe telefonul
                            mobil;
                          </li>
                          <li>
                            Vă permite controlul angajaților în cazul unui
                            eveniment, prin repartizarea de coduri individuale
                            pentru fiecare dintre aceștia. Sistemul permite
                            stocarea în memoria de evenimente a codului persoanei
                            care armează și dezarmează;
                          </li>
                          <li>
                            Beneficiați de reduceri pentru asigurarea locuinței
                            sau spațiilor comerciale;
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h3>Sisteme de Interfonie & Videointerfonie.</h3>
                  <p>
                    O variantă tot mai des utilizată în ultimul timp sunt cele de
                    interfoane și videointerfoane. Cu ajutorul unui astfel
                    de sistem puteți afla foarte simplu pe cine urmează să
                    primiti în incinta propietății dumneavoastră, puteți
                    comunica și permite sau refuza accesul acestei persoane. În
                    plus cu ajutorul unui sistem de interfonie sau videointerfonie,
                    aveți posibilitatea de a vedea persoana care apelează.
                  </p>
                  <p>
                    Noutatea sunt videointerfoanele, după cum sugerează și
                    numele, interfoane cu posibilitatea vizualizării celui care
                    apelează cu sau fără comunicare audio.
                  </p>
                  <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                    <div class="panel wt-panel">
                      <div class="card-sam">
                        <div class="card-header-sam">
                          <h3 class="card-title-sam">Soluțiile tehnice sisteme de interfonie
                            videointerfonie oferite de noi
                            sunt:</h3>
                          <button class="collapse-button-sam">-</button>
                        </div>
                        <div class="card-body-sam">
                          <ul>
                            <li>Posturi exterioare și interioare;</li>
                          <li>Yale electomagnetice;</li>
                          <li>Control acces.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="panel wt-panel">
                      <div class="card-sam">
                        <div class="card-header-sam">
                          <h3 class="card-title-sam">Elemente:</h3>
                          <button class="collapse-button-sam">-</button>
                        </div>
                        <div class="card-body-sam">
                          <ul>
                            <li>unitate de exterior ce conține camera video;</li>
                          <li>sursa de tensiune;</li>
                          <li>
                            monitor video ( LCD sau LED color cu sau fără
                            touchscreen);
                          </li>
                          <li>
                            cabluri de transmisie semnal sau emițător receptor.
                          </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>Sisteme de control acces</h3>
                  <p>
                    Un instrument de management și gestionare a intrărilor și
                    ieșirilor dintr-o anumită zonă sau clădire având misiunea de
                    protejare a personalului, clădirilor, echipamentelor,
                    depozitelor și datelor confidențiale.
                  </p>
                  <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                    <div class="panel wt-panel">
                      <div class="card-sam">
                        <div class="card-header-sam">
                          <h3 class="card-title-sam">Soluții tehnice oferite</h3>
                          <button class="collapse-button-sam">-</button>
                        </div>
                        <div class="card-body-sam">
                          <ul>
                            <li>Centrale de control acces;</li>
                          <li>
                            Cititoare și carduri magnetice, de proximitate,
                            biometrice, cod bare;
                          </li>
                          <li>
                            Yale, electromagneți, amortizoare uși, butoane
                            ieșire/evacuare;
                          </li>
                          <li>Software control acces-pontaj.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="panel wt-panel">
                      <div class="card-sam">
                        <div class="card-header-sam">
                          <h3 class="card-title-sam">Prin folosirea unor sisteme de control acces puse la
                            dispoziție de SKL Technion Solution se pot vizualiza</h3>
                          <button class="collapse-button-sam">-</button>
                        </div>
                        <div class="card-body-sam">
                          <ul>
                            <li>
                              situația prezenței și a pontajului personalului
                              dumneavoastră;
                            </li>
                            <li>
                              zonele de acces pentru fiecare persoană în parte;
                            </li>
                            <li>
                              perioadele de timp în care acestea au acces într-o
                              anumită locație cu risc mai mare sau mai mic din
                              punct de vedere al securității;
                            </li>
                            <li>
                              posibilitatea gestionării prezenței persoanelor cu
                              drepturi diferite de acces în anumite zone din
                              diverse locații;
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    <b>Sisteme de Control Acces. Capacitatea de
                      interconectare</b>
                  </p>
                  <p>
                    La toate acestea departamentul tehnic al SKL Technion
                    Solution poate oferi programe specializate de pontaj, care
                    preiau informațiile din sistemul de control al accesului și
                    generează o gamă complexă de rapoarte de pontaj, grafice și
                    analize periodice, exportul datelor în formate compatibile
                    cu aplicațiile pentru calcularea salariilor.
                  </p>
                  <p>
                    Un alt avantaj competitiv al SKL Technion Solution este capacitatea
                    dovedită de interconectare a sistemului de control acces la
                    un sistem de avertizare detecție efracție și detecție
                    incendiu ce poate pune la dispoziție diferite dispozitive de
                    automatizare (aprinderea/stingerea luminii, ventilația sau
                    aerul condiționat, etc).
                  </p>
                  <p>
                    Aplicațiile în care sunt folosite sistemele de control
                    acces: clădiri industriale, clădiri rezidențiale, birouri,
                    stadioane, parcări, hoteluri, bănci etc.
                  </p>
                  <p>
                    Știm cum să creăm cel mai productiv mediu de lucru pentru
                    dumneavoastră!
                  </p>
                  <p>
                    Așadar, găsim pentru dumneavoastră cea mai potrivită soluție
                    în materie de Sisteme de Control Acces, astfel încât putem
                    oferi clienților noștri o experiență uimitoare și, de
                    asemenea, servicii de cea mai bună calitate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>