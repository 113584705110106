<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <div class="section-content">
          <div class="container">
            <div class="row d-flex justify-content-center flex-wrap ">
              <div class="col-lg-12 col-md-12 m-b30 text-justify">
                <h3>Instalații electrice interioare și exterioare, curenți tari și slabi</h3>
                <p>
                  <b>
                    Din punctul de vedere al locului unde se execută,
                    instalațiile electrice putem executa</b>
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    Instalații electrice interioare, care cuprind conductoarele
                    cu toate elementele accesorii de montaj, întrerupere,
                    protecții ce se monteaza în interiorul clădirilor de orice
                    fel;
                  </li>
                  <li>
                    Instalații electrice exterioare, la care elementele
                    instalației sunt montate în afara clădirilor.
                  </li>
                </ul>
                <p>
                  <b>
                    Din punct de vedere al intensității curentului și al
                    destinaţiei (receptoarele alimentate), instalaţiile electrice
                    pe care le putem executa sunt:</b>
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="card-sam">
                    <div class="card-header-sam">
                      <h3 class="card-title-sam">1. Instalații de curenți tari:</h3>
                      <button class="collapse-button-sam">-</button>
                    </div>
                    <div class="card-body-sam">
                      <ul>
                        <li>instalații de forță, cuprinzând instalațiile ce deservesc echipamentele destinate nemijlocit aplicării energiei electrice (producere, transport, distribuție, consum) în scop util de producere de lucru mecanic, căldura, procese electrochimice</li>
                        <li>instalații de iluminat electric</li>
                        <li>instalații de automatizare, măsură și control</li>
                        <li>instalații pentru compensarea puterii reactive</li>
                        <li>instalații pentru reducerea regimului deformat</li>
                        <li>instalații de protecție împotriva șocului electric</li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">2. Instalaţii de curenţi slabi:</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>instalaţii de telecomunicaţii</li>
                        <li>instalaţii de detectare automată si de alarmă</li>
                        <li>
                          instalaţii de telesupraveghere a funcţionarii
                          instalaţiilor de curenţi tari din clădiri
                        </li>
                        <li>instalaţii de ceasoficare</li>
                        <li>instalaţii de telecomanda și telemăsurare</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  <b>
                    La proiectarea cât și executarea instalațiilor electrice interioare pentru iluminat normal și prize
                    se ține seamă de următoarele aspecte:</b>
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    Circuitele pentru iluminat vor fi separate de circuitele de
                    prize;
                  </li>
                  <li>
                    Se admit doze centralizate comune pentru circuitele de iluminat, prize, comenzi și semnalizări numai
                    dacă aceste circuite funcționează la aceeași tensiune;
                  </li>
                  <li>
                    Se prevăd circuite monofazate de iluminat pentru o putere totală instalată de maxim 3kW și circuite
                    trifazate pentru o putere totală instalată de maxim 8 kW;
                  </li>
                  <li>
                    Numărul total de corpuri de iluminat pe fază nu va depași 30;
                  </li>
                  <li>
                    Aparatele de conectare folosite pentru circuitele electrice ale lămpilor fluorescente vor avea un
                    curent nominal de minim 10 A.
                  </li>
                  <li>
                    Corpurile de alimentat se vor alimenta între fază și nul; la partea filetată a duliei lămpii se va
                    lega conducta nul, iar la borna de interior a duliei se va lega conducta de fază trecută prin
                    întrerupator;
                  </li>
                  <li>
                    Circuitele pentru prizele de utilizare generală vor fi încarcate cu maxim 15 prize simple sau duble;
                  </li>
                  <li>
                    Întrerupătoarele, comutatoarele și butoanele pentru iluminat se vor lega numai pe conducte de fază;
                    înalțimea de montare va fi de 1,5 m de la nivelul pardoselii finite;
                  </li>
                  <li>
                    Prizele la instalațiile electrice interioare de iluminat normal și prizele se vor monta la
                    următoarele înălțimi de la pardoseala finită:
                  </li>
                </ul>
                <ul class="sublist">
                  <li>
                    la 0.7 - 1,2 m în încaperi pentru băi, grupuri sanitare;
                  </li>
                  <li>peste 0,2 m în alte încăperi.</li>
                </ul>
                <ul class="list-check-circle primary">
                  <li>
                    Întreruptoarele, comutatoarele și prizele se vor monta la minim 0,8 m de la elementele metalice în
                    legatura cu pământul.
                  </li>
                  <li>
                    Corpurile de iluminat de orice tip vor fi alimentate numai între fază și nul.
                  </li>
                </ul>
                <h3>Iluminat de siguranță și antipanică</h3>
                <p>
                  <b>
                    Normativul I7/2011 abordează soluțiile de alimentare a
                    sistemelor de iluminat de siguranță/securitate în două
                    capitole distincte, respectiv:</b>
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    „5.6. Sisteme de alimentare cu energie electrică pentru
                    servicii de securitate”(Exemple de servicii de securitate
                    sunt: iluminatul de siguranță/securitate).
                  </li>
                  <li>
                    „7.23.12.1 Corpurile de iluminat de tip autonom (executate
                    conform SREN 60598-2-22) se alimentează pe circuite din
                    tablourile de distribuție pentru receptoare normale.
                  </li>
                </ul>
                <p>
                  Pot fi alimentate de pe circuite comune cu corpurile de
                  iluminat pentru iluminatul normal.
                </p>
                <p>
                  <b>Același Normativ:</b> Pentru sistemul de iluminat de
                  securitate împotriva panicii, impune o cerință specială,
                  cumulativă celor prevăzute tuturor sistemelor de iluminat de
                  siguranță/securitate respectiv.
                </p>
                <p>
                  <b>Pe lângă cerința: „7.23.9.2. :</b> Iluminatul de securitate
                  împotriva panicii se prevede cu comandă automată de punere în
                  funcțiune după căderea iluminatului normal”,
                </p>
                <p>
                  <b>Se impune și cerința: „7.23.9.3. :</b> În afară de comanda
                  automată a intrării lui în funcțiune, iluminatul de securitate
                  împotriva panicii se prevede și cu comenzi manuale din mai
                  multe locuri accesibile personalului de serviciu al clădirii,
                  respectiv personalului instruit în acest scop”.
                </p>
                <p>
                  Scoaterea din funcțiune a iluminatului de securitate împotriva
                  panicii trebuie să se facă numai dintr-un singur punct
                  accesibil personalului însărcinat cu aceasta.
                </p>
                <p>
                  Sistemul de instalații electrice pentru iluminat general, siguranță și antipanică, este parte a iluminatului de
                  securitate.
                </p>
                <p>
                  Rolul este de a evite panica și asigurarea nivelului de
                  iluminare ce permite vizibilitate până în locul de unde calea
                  de evacuare poate fi identificată.
                </p>
                <p>
                  Sistemul de iluminat împotriva panicii impune, în spații cu
                  suprafață mare și sau cu multe persoane.
                </p>
                <p>
                  Realizarea unui nivel de iluminare de minim „10% din nivelul
                  de iluminare normat pentru iluminatul general, dar nu mai mic
                  de 20 lx”.
                </p>
                <p>
                  Această valoare a iluminării se poate realiza cu mai multe
                  corpuri, amplasate corespunzător.
                </p>
                <p>
                  <b>Fluxul necesar:</b>
                  Fluxul necesar a fi instalat în respectivele corpuri este mult
                  superior celui emis de corpurile de iluminat tip luminobloc,
                  utilizate pentru a marca:
                </p>
                <ul class="list-check-circle primary">
                  <li>o ieșire;</li>
                  <li>traseul căilor de evacuare;</li>
                  <li>prezența hidranților;</li>
                </ul>
                <p>
                  Corpurile de iluminat ale sistemului de iluminat împotriva
                  panicii se integrează printre cele ale sistemului de iluminat
                  normal :
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    se montează pe același tavan, dar nu sunt comune cu acestea.
                  </li>
                </ul>
                <p>
                  <b>Corpurile de iluminat:</b> Dacă corpurile de iluminat cu
                  care se realizează sistemul de iluminat de securitate
                  împotriva panicii sunt cu sursă inclusă, alimentate de pe
                  același circuit cu corpurile sistemului de iluminat normal, la
                  un defect pe circuit, sistemul de iluminat de panică intră
                  automat în funcțiune.
                </p>
                <p>
                  <b>În spațiile în care există posibilitatea instaurării panicii
                    se impune ca:</b>
                  O persoană instruită să pună în funcție sistemul de iluminat
                  împotriva panicii, cu aparate de comandă accesibile numai
                  acestuia și din puncte neafectate de prezența persoanelor
                  aflate în stare de panică.
                </p>
                <h3>Instalații electrice de legare la pământ</h3>
                <p>
                  Prin împământare se înţelege ansamblul format din electrozi îngropaţi în pământ, legaţi între ei prin
                  conductoarele de legare la pământ, montate între electrozi şi între priza de pământ în ansamblu şi
                  instalaţiile electrice.În funcţie de componentele folosite pentru construcția electrozilor de legare
                  la pământ, prizele de pământ, pot fi împărțite în naturale și artificiale.
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    <b>Electrozii naturali de legare la pământ:</b> Includ
                    elemente metalice înglobate în fundații (armătura din
                    fundațiile clădirilor) și alte părți metalice ce au un bun
                    contact cu pământul.
                  </li>
                  <li>
                    <b>Electrozii artificiali de legare la pământ:</b> Includ
                    electrozi plasați orizontal sau vertical în pământ în afara
                    fundațiilor, în diferite configurații.
                  </li>
                </ul>
                <p>
                  <b>
                    Verificare instalații electrice de legare la pământ și de protecție împotriva trăznetelor</b>
                </p>
                <p>
                  Datorită influenței factorilor externi (coroziune), este
                  foarte important să le verificăm regulat și cu precizie prin
                  executarea unor măsurări precise.<br />
                  Scopul este acela, de a confirma că instalaţia de împământare
                  este conform I7/2011 sub toate aspectele, se fac după un
                  program, ori de câte ori se fac modificări sau reparaţii.
                  <br />
                  Verificarea cuprinde inspectarea documentaţiei tehnice,
                  verificări vizuale, realizarea de măsurători finalizate cu
                  Buletine de verificare, iar rezultatele vor fi înregistrate
                  într-un raport de verificare.
                </p>
                <p>
                  <b>
                    Încercările care se fac în mod frecvent la o instalaţie de
                    împământare sunt:</b>
                </p>
                <ul class="list-check-circle primary">
                  <li>Incercări de continuitate a conductoarelor</li>
                  <li>Măsurarea rezistenței de dispersie a prizei de pământ.</li>
                </ul>
                <p>
                  <b>
                    Rezistenţa de dispersie a prizei de pământ, trebuie să se
                    încadreze în următoarele valori:</b>
                </p>
                <ul class="list-check-circle primary">
                  <li>
                    Maxim 4 ohmi pentru instalaţiile electrice comune de
                    utilizare
                  </li>
                  <li>
                    Maxim 1 ohm dacă instalaţia de împământare este comună atât pentru paratrăsnet cât şi pentru
                    instalaţia electrică comuna de utilizare
                  </li>
                  <li>
                    Maxim 10 ohmi pentru instalaţia de împământare a paratrăsnetului.
                  </li>
                </ul>
                <p>
                  <b>
                    Măsurători instalatii electrice de legare la pământ – Măsurarea rezistenței de dispersie a prizei de
                    pământ:</b>
                </p>
                <p>
                  În conformitate cu Normativul I7/2011, este obligatorie
                  verificarea prizelor de pământ înainte de recepționare şi
                  punere în funcţiune şi apoi prin verificări periodice.
                  Verificările trebuie să demonstreze că rezistența de dispersie
                  a prizei de pământ corespunde valorii pentru protecția
                  persoanelor și a bunurilor în orice anotimp. Rezultatul
                  măsurătorii, traseele de măsurare și distanțele alese pentru
                  sonde trebuiesc înregistrate astfel încât la verificări
                  ulterioare să se poată efectua comparări ale valorilor
                  măsurate.
                </p>
                <p>
                  <b>
                    Verificările în instalații electrice de legare la pământ trebuie efectuate de persoane calificate,
                    cu competențe în măsurători şi sunt:</b>
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Verificări iniţiale se fac prin inspecţie şi încercări
                          și se realizează</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>în timpul montării,</li>
                        <li>la finalizarea unei instalaţii noi,</li>
                        <li>
                          la finalizarea unei extinderi sau modificări într-o
                          instalaţie, înainte de punerea în funcţiune.
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p><b>Verificările periodice</b></p>
                <p>
                  Au rolul de a determina dacă parametrii instalaţiei electrice
                  supuse verificării sunt respectaţi şi confirmaţi prin
                  măsurători
                </p>
                <p>
                  <b>Metode de măsurare :</b> Rezistentele de dispersie a prizelor de pamant, se pot determina cu o
                  precizie mai mare sau mai mică, funcție de metodă de masurare folosită.
                </p>
                <p>
                  Metoda aleasă va fi aceea care da un rezultat cât mai corect,
                  indiferent de rezistenţa de dispersie a prizelor, a ţaruşilor
                  sau a altor materiale auxiliare şi care necesită o dotare
                  minimă de aparate şi un timp cât mai scurt de mâsurare.
                </p>
                <p><b>Metode de testare a prizei de pământ</b></p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Verificări iniţiale se fac prin inspecţie şi încercări
                          și se realizează</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Cădere de tensiune – testare cu trei şi patru poli
                          </li>
                          <li>Testarea selectivă (cu un cleşte)</li>
                          <li>Testarea fară ţăruşi (cu doi cleşti)</li>
                          <li>Metoda celor doi poli</li>
                          <li>Metoda impulsului*</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  * Conform Standardului “EN 62305 – Protecția la trăsnet”,
                  trebuie realizată și măsurarea impedanței legăturii la pământ,
                  care implică luarea în calcul a reactanței inductive a
                  electrozilor de legare la pământ în plus față de rezistența
                  lor. Pe durata unui trăsnet numai o parte din sistemul de
                  legare la pământ participă la descărcarea în pământ a
                  curentului generat. Măsurarea reactanței inductive oferă date
                  despre această descărcare. Metoda impulsului de tensiune
                  folosită pentru măsurarea impedanței legăturii la pământ este
                  bazată pe generarea de impulsuri cu o formă corespunzătoare
                  unei lovituri de trăsnet. Impedanțele măsurate în acest fel
                  corespund cu impedanțele legăturilor la pământ în cazul
                  trăsnetelor și valorile lor pot fi comparate cu cerințele
                  definite în standardul de mai sus. Standardul oferă (printre
                  altele) cerințele pentru clădiri cu pericol de incendiu sau
                  explozie și pentru stațiile de alimentare cu combustibil.
                </p>
                <h3>Instalații de paratrăsnet</h3>
                <p>
                  Paratrăsnetul reprezintă o investiție pe cât de simplă pe atât
                  de utilă. Acesta este imperios necesar pentru activitatea de
                  protecție a muncii dar și pentru protejarea clădirilor în
                  general. Toate normele de protecție a muncii prevăd instalarea
                  de paratrăsnete pe clădiri cu scopul de a spori siguranța în
                  momentul în care au loc furtuni și descărcări electrice.
                </p>
                <p>
                  <b>
                    Instalatii de paratrasnet – din ce este format un sistem
                    paratrăsnet?</b>
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Tijele de captare</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Micile componente cu rolul de a acționa ca terminal
                            pentru a se descărca fulgerul. Acestea sunt de mărimi
                            și forme diferite, cele mai multe dintre ele au vârful
                            ascuțit sau formă de sfera lucioasă.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Conductoarele de coborâre</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            După ce curenții electrici sunt primiți în tijele de
                            captare, conductoarele de coborâre redirecționează
                            curentul către pământ. Acestea sunt conectate cu
                            tijele pentru captare și sunt puse pe acoperiș sau la
                            colțurile acoperișului spre priza de pământ. Prizele
                            de pământ sunt elementele cheie din sistemul de
                            protecție și au rol fundamental foarte important. În
                            componența prizelor de pământ se găsesc și electrozii,
                            care sunt îngropați adânc în pământ în jurul
                            structurilor protejate.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p><b>Conductoarele de coborâre și prizele</b></p>
                <p>
                  Sunt componente importante ale unei instalații de paratrăsnet.
                  Obiectivul principal al unui paratrăsnet este să devieze
                  curentul electric descărcat din trăsnet spre pământ. Este
                  foarte important să se amplaseze corect conductoarele de
                  coborâre și să se asigure o bună funcționare a prizelor de
                  pământ. Stabilirea necesitații de protecție la transfer și a
                  tipului de instalație de protecție care va fi utilizat, se
                  face <b>doar</b> în urma <b>evaluării riscului de trăsnet</b>.
                  Efectele loviturilor de trăsnet asupra siguranței oamenilor,
                  instalațiilor și clădirilor, pot să fie unele dintre cele mai
                  distructive. În fiecare an sunt sute de case sau alte
                  proprietăți care sunt stricate, deteriorate sau distruse de
                  către loviturile de trăsnet.
                </p>
                <p>
                  Avarierea fizică a construcțiilor, vătămarea ființelor vii,
                  deteriorarea sistemelor electrice și electronice sunt cele mai
                  importante avarii produse de către loviturile de trăsnet. În
                  momentul în care este analizată posibilitatea de a instala
                  sisteme de protecție la trăsnet este important să se evalueze
                  cât mai corect acest risc. Nevoia de a avea o instalație
                  paratrăsnet ar trebui să reiasă din evaluarea riscurilor care
                  sunt asociate cu loviturile de trăsnet asupra construcțiilor.
                </p>
                <h3>Tablouri electrice</h3>
                <p>
                  Într-o instalație electrică tabloul electric reprezintă cel mai important echipament care asigură
                  alimentarea cu energie electrica a tuturor circuitelor dintr-o clădire și în același timp îi oferă
                  acesteia și o siguranță și protecție necesară.
                </p>
                <p>
                  Pentru ca siguranța și durabilitatea instalațiilor electrice a clienților începe de la proiectarea și
                  execuția circuitelor și a tablourilor electrice de distribuție și automatizare, avem proceduri clar
                  stabilite, electricieni cu experiență și activități normale; astfel, oferim uzinarea de tablouri
                  electrice și de automatizare dimensionate adecvat și eficient, în funcție de cerințele de utilizare
                  din facilitatea de producție.
                </p>
                <p>
                  Soluția propusă de noi, în funcție de nevoile dumneavoastră, acoperă furnizarea de tablouri electrice
                  în limita a 3200 A, acoperind la cheie tot procesul de realizare a tablourilor electrice:
                </p>
                <ul class="list-check-circle primary">
                  <li>Echipare</li>
                  <li>Cablare</li>
                  <li>Etichetare</li>
                  <li>Probe și încercări</li>
                  <li>Livrare</li>
                  <li>Punere în funcțiune</li>
                  <li>Asistență tehnică</li>
                </ul>
                <h3>Instalații de automatizări electrice</h3>
                <p>
                  O automatizare industrială complexă menține fluxul tehnologic neîntrerupt. Aceasta elimină posibilele
                  întarzieri cauzate de problemele ce pot apărea la personalul uman și crește randamentul proceselor,
                  prin continuitate.
                </p>
                <p>
                  Misiunea noastră este de a oferi cel mai bun seriviciu tehnic de proiectare și executare a
                  instalațiilor electrice cu un înalt grad de automatizare și control pentru orice tip de proiect.
                </p>
                <p>
                  De la modernizarea și actualizarea echipamentelor industriale de automatizare și electrice existente
                  până la înlocuirea pieselor învechite, realizăm proiecte complete de automatizare cu proiectare,
                  furnizare, instalare și punere în funcțiune a unei soluții complete de automatizare industrială.
                </p>
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="card-sam">
                      <div class="card-header-sam">
                        <h3 class="card-title-sam">Vă putem oferi soluții dintre cele mai complexe adaptate nevoilor voastre.</h3>
                        <button class="collapse-button-sam">-</button>
                      </div>
                      <div class="card-body-sam">
                        <ul>
                          <li>
                            Tablouri pentru instalații electrice și de automatizare;
                          </li>
                          <li>
                            Sisteme computerizate de masura, reglare, comandă și semnalizare a parametrilor instalațiilor
                            industriale (temperatura, presiune debit, nivel, marimi electrice, stări contacte, etc.);
                          </li>
                          <li>Instalații de automatizare electrice;</li>
                          <li>
                            Sisteme complete de monitorizare și control a proceselor industriale, de la actuatori și
                            senzori din teren.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FAQ SECTION END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>